<template>
  <v-app>
    <transition name="fade-in-up">
      <BLList :headers="headersBL" :items="itemsBL" @initialize="initialize" :search_tool="true"></BLList>
    </transition>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import BLList from "@/views/pages/bl/BLList.vue";
import genericService from "./../../../services/generic.service";

export default {
  components: {
    BLList
  },

  data: () => ({
    uri:"bl",
    headersBL: [
      { text: "B/L N°", value: "id", sortable: true, filterable: true },
      { text: "WO", value: "work_order", sortable: false, filterable: false },
      { text: "CNTR", value: "cntr", sortable: false },
      { text: "Actions", value: "actions", sortable: false }
    ],
    itemsBL: []
  }),

  created(){
    this.initialize();
  },

  methods: {
    initialize() {
      var _self = this;
      genericService.getRecordList('/'+this.uri, function(records){
          _self.itemsBL = records
      });
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "B/L" }]);
  }
};
</script>
